import React from 'react'
import { NavLink } from 'react-router-dom';
import {
    Box,
    Container,
    Divider,
    Flex,
    HStack,
    Link,
    Text,
} from '@chakra-ui/react'
import {
    FB,
    Instagram,
    LinkedIn,
    Twitter,
} from 'assets/icons'
import CookiesModal from 'components/cookies/cookies';


function Footer() {

    const scrollToTopAndNavigate = () => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    };

    return (
        <>
            <CookiesModal />
            <Box
                as="footer"
                textAlign="center"
                bg="#0A2540"
                pt={{ base: '40px', md: '45px' }}
                pb={{base: "40px", md: "45px"}}
                // minHeight="80vh"
            >
                <div>
                    <Container maxW="1280px">
                        <Text
                            // m="25px auto"
                            fontSize={{ base: '10px', md: '15px' }}
                            textAlign={{base:"justify",sm:"center"}}
                            lineHeight={{ base: '0.8rem', md: '2rem' }}
                            color="#918f8e"
                        >
                            TagoCash is not a bank nor a payment service provider. TagoCash does not provide any exchange services between virtual currencies and fiat currencies. TagoCash does not provide custodial wallet services. TagoCash is not a crypto service provider. TagoCash does not insure deposits.
                        </Text>

                        <HStack justify="center" mt={{base:"2rem",sm:"3rem"}}>
                            <Text variant="white" fontSize="24px" fontWeight="900">
                                TagoCash
                            </Text>
                            <Flex gap="23px" borderLeft="1px" pl="1rem">
                                <FB />
                                <Twitter />
                                <LinkedIn />
                                <Instagram />
                            </Flex>
                        </HStack>
                        <HStack
                            justify="center"
                            gap={{ base: '20px', md: 'unset' }}
                            flexWrap="wrap"
                            mt={{base:"2rem",sm:"3rem"}}
                            align="flex-start"
                        >
                            {' '}
                            <Link
                                color="#fff"
                                cursor="pointer"
                                fontSize="18px"
                                minW={{ base: '200px', md: '100px' }}
                                margin="0"
                            >
                                <NavLink to="/" onClick={scrollToTopAndNavigate}>
                                    Home
                                </NavLink>
                            </Link>{' '}
                            <Divider
                                orientation="vertical"
                                h="26px"
                                borderColor="#fff"
                                display={{ base: 'none', md: 'block' }}
                            />
                            <Link
                                color="#fff"
                                fontSize="18px"
                                cursor="pointer"
                                margin="0"
                                minW={{ base: '200px', md: '175px' }}
                            >
                                <NavLink to='/terms' onClick={scrollToTopAndNavigate}>
                                    Terms of Service
                                </NavLink>
                            </Link>{' '}


                            <Divider
                                orientation="vertical"
                                h="26px"
                                borderColor="#fff"
                                display={{ base: 'none', md: 'block' }}
                            />{' '}

                            <Link
                                color="#fff"
                                fontSize="18px"
                                cursor="pointer"
                                margin="0"
                                minW={{ base: '200px', md: '100px' }}
                            >
                                <NavLink to="/privacy" onClick={scrollToTopAndNavigate}>
                                    Privacy
                                </NavLink>
                            </Link>


                            <Divider
                                orientation="vertical"
                                h="26px"
                                borderColor="#fff"
                                display={{ base: 'none', md: 'block' }}
                            />{' '}

                            <Link color="#fff" fontSize="18px" minW="175px" margin="0">
                                <NavLink to="/fees" onClick={scrollToTopAndNavigate}>
                                    About our Fees
                                </NavLink>
                            </Link>

                            <Divider
                                orientation="vertical"
                                h="26px"
                                borderColor="#fff"
                                display={{ base: 'none', md: 'block' }}
                            />
                            <Link
                                color="#fff"
                                fontSize="18px"
                                margin="0"
                                minW={{ base: '200px', md: '120px' }}
                            >
                                <NavLink to="/contact" onClick={scrollToTopAndNavigate}>
                                    Contact Us
                                </NavLink>
                            </Link>
                        </HStack>
                        <Text m="25px auto" maxW="660px"
                        fontSize={{ base: '10px', md: '15px' }}
                        textAlign={{base:"justify", sm:"center"}}
                        lineHeight={{ base: '0.8rem', md: '2rem' }}
                        >
                            TagoBits, Inc. is a fintech company operating at the frontier of the digital and the fiat currency. TagoCash is a global community project supported by Stellar. Its mission is to provide financial equinimity to people all over the world.
                        </Text>
                    </Container>
                    <Text variant="white" fontSize={{base:"11px",sm:"16px"}} margin="0">
                        TagoCash is ©{new Date().getFullYear()} Trademark Registered
                        by TagoBits, Inc.
                    </Text>
                </div >
            </Box>
        </>
    );
}

export default Footer;
