import React, { useState, useEffect } from 'react';
import { ChakraProvider, withDefaultColorScheme, extendTheme } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Landing from 'views/landing';
import Navbar from 'components/header'
import Footer from 'components/footer'
import TagoWallet from 'views/pages/tagowallet';
import SignUp from 'views/pages/signup';
import FaqPage from 'views/pages/faq';
import ContactUs from 'views/pages/contact';
import PageNotFound from "views/pages/404/PageNotFound";
import BlogContent from 'views/pages/blog/BlogContent';
import Blogs from 'views/pages/blog';
import customTheme from 'assets/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Privacy from './views/pages/terms/privacy';
import Terms from './views/pages/terms/terms';
import Fees from './views/pages/terms/fees';
import ThankYou from './views/pages/thankyou/thankyou';
import TagoLottie from "./assets/images/lottieFiles/tagolotie2.0 + text 2.json";
import Lottie from "react-lottie";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const theme = extendTheme(
  customTheme,
  withDefaultColorScheme({
    colorScheme: 'primary',
  })
);

const contactOptions = {
  loop: true,
  autoplay: true,
  animationData: TagoLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function App() {
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 3040);
  }, []);

  return (
    <>
      <ToastContainer />
      {loading ? (
        <div className="app-container">
          <div className="loading-container">
            <Lottie options={contactOptions} height={250} width={250} />
          </div>
        </div>
      ) : (
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/wallet" element={<TagoWallet />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/blog" element={<Blogs />} />
              <Route path="/blogcontent/:id" element={<BlogContent />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/fees" element={<Fees />} />
              <Route path="/thankyou" element={<ThankYou />} />
              <Route path={"/*"} element={<PageNotFound />} />
            </Routes>
            <Footer />
          </BrowserRouter>
        </ChakraProvider>
      )}
    </ >
  );
}

export default App;
